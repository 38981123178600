body {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    background-color: #f6f6f6;
}

nav {
    display: flex;
    padding: 2.5rem;
    justify-content: space-between;
    align-items: center;
}

.navbar-links-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 2.8125rem;
}

.navbar-links-container a {
    color: var(--Lillup---Black, #2D2E2E);

    /* Button */
    font-family: sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.nav-logo-container {
    width: 5.19606rem;
    height: var(--40, 2.5rem);
    flex-shrink: 0;
}

.scroll-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    gap: 0.625rem;
}

.scroll-icon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2px;
}

.primary-button {
    border-radius: 0.625rem;
    background: var(--Lillup---Red, #EC1F24);
    color: #FFFFFF;
    display: flex;
    height: 2.4375rem;
    padding: 1.0625rem 1.875rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
}

.navbar-cart-icon {
    font-size: 1.15rem;
}

.navbar-menu-container svg {
    font-size: 1.5rem;
    cursor: pointer;
}

.home-banner-container {
    position: relative;
    display: flex;
    padding: 5rem 2.5rem 0 9rem;
    justify-content: space-between;
}



.home-image-section {
    max-width: 600px;
    flex: 1;
}

.home-text-section {
    display: flex;
    width: 35.0625rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 3.75rem;
}

.primary-heading {
    color: var(--Lillup---Black, #2D2E2E);

    /* H1 */
    font-family: sans-serif;
    font-size: 3.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.primary-text {
    color: var(--Lillup---Black, #2D2E2E);

    /* Menu Title */
    font-family: sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.contact-page-primary-text {
    color: var(--Lillup---Black, #2D2E2E);
    text-align: center;
    /* Sub Heading */
    font-family: sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.contact-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 6rem 0rem;
}